import axios from "axios";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import * as Constants from "../../Constants/index.js";
import Accodion from "../Helpers/Accodion";
import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import Toast from "../Toast.js";

export default function Faq() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      subject: Yup.string().required("Subject is required"),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      // You can send the registration data to your API here using axios
      axios
        .post(Constants.api + "get-in-touch", values)
        .then((response) => {
          if (response && response.data && response.data.success) {
            console.log(response.data.message);
            toast.success(<Toast message={response.data.message} />, {
              position: "top-right",
              autoClose: 1400,
              onClose: () => {
                setIsLoading(false);
                setTimeout(() => {
                  navigate("/");
                }, 500);
              },
            }
            );
          } else {
            toast.error(<Toast message={response.data.message} />, {
              position: "top-right",
              autoClose: 2000,
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Form submit failed", error);
        });
    },
  });
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="faq-page-wrapper w-full mb-10">
        <div className="page-title w-full">
          <PageTitle
            title="Frequently Asked Questions"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "FAQ", path: "/faq" },
            ]}
          />
        </div>
      </div>
      <div className="contact-wrapper w-full mb-10">
        <div className="container-x mx-auto">
          <div className="main-wrapper w-full lg:flex lg:space-x-[30px]">
            <div className="lg:w-1/2 w-full mb-10 lg:mb-0">
              <h1 className="text-qblack font-bold text-[22px] mb-4">
                Frequently asked questions
              </h1>
              <div className="flex flex-col space-y-7 justify-between">
                <Accodion
                  title="01. How does information technology work?"
                  des="There are many variations of passages of Lorem Ipsum available into the 
but the majority have suffered alteration in some form, by injecte find to a
humour, or randomised words"
                />
                <Accodion
                  init
                  title="02. How can I become IT manager?"
                  des="There are many variations of passages of Lorem Ipsum available into the 
but the majority have suffered alteration in some form, by injecte find to a
humour, or randomised words"
                />
                <Accodion
                  title="03. What are the latest trends in IT?"
                  des="There are many variations of passages of Lorem Ipsum available into the 
but the majority have suffered alteration in some form, by injecte find to a
humour, or randomised words"
                />
                <Accodion
                  title="04. How long should a business plan be?"
                  des="There are many variations of passages of Lorem Ipsum available into the 
but the majority have suffered alteration in some form, by injecte find to a
humour, or randomised words"
                />
                <Accodion
                  title="05. How work the support policy?"
                  des="There are many variations of passages of Lorem Ipsum available into the 
but the majority have suffered alteration in some form, by injecte find to a
humour, or randomised words"
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="bg-white sm:p-10 p-5">
                <div className="title flex flex-col items-center">
                  <h1 className="lg:text-[34px] text-xl font-bold text-qblack">
                    Have Any Qustion
                  </h1>
                  <span className="-mt-5 block">
                    <svg
                      width="354"
                      height="30"
                      viewBox="0 0 354 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 28.8027C17.6508 20.3626 63.9476 8.17089 113.509 17.8802C166.729 28.3062 341.329 42.704 353 1"
                        stroke="#FFBB38"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </span>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div className="inputs mt-5">
                    <div className="mb-4">
                      <InputCom
                        label="Name*"
                        placeholder="Name"
                        name="name"
                        inputClasses="h-[50px]"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <span className="errorMsg">{formik.errors.name}</span>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <InputCom
                        label="Email Address*"
                        placeholder="Email Address*"
                        name="email"
                        inputClasses="h-[50px]"
                        type="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <span className="errorMsg">{formik.errors.email}</span>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <InputCom
                        placeholder="Subject"
                        label="Subject*"
                        type="text"
                        id="subject"
                        name="subject"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.subject}
                        className={`input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none`}
                      />
                      {formik.touched.subject && formik.errors.subject ? (
                        <span className="errorMsg">{formik.errors.subject}</span>
                      ) : null}
                    </div>
                    <div className="mb-5">
                      <h6 className="input-label text-qgray capitalize text-[13px] font-normal block mb-2 ">
                        Message*
                      </h6>
                      <textarea
                        id="message"
                        name="message"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                        placeholder="Type your message here"
                        className="w-full h-[105px] focus:ring-0 focus:outline-none p-3 border border-qgray-border placeholder:text-sm"
                      ></textarea>
                      {formik.touched.message && formik.errors.message ? (
                        <span className="errorMsg">{formik.errors.message}</span>
                      ) : null}
                    </div>
                    <div>
                      <div className="black-btn text-sm font-semibold w-full h-[50px] flex justify-center items-center">
                        <button
                          type="submit"
                          disabled={isLoading}
                          className="black-btn text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center send-now"
                        >
                          <span>{isLoading ? "Sending..." : "Send Now"}</span>
                        </button>

                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
